import React, { useCallback, useContext, useEffect, useState } from "react"
import * as styles from "./detail.module.css"
import queryString from "query-string"
import api from "apis"
import { navigate } from "gatsby-link"
import Layout from "components/common/layout.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getNoticeListAsync } from "store/modules/postReducer.js"
import modalContext from "context/modalContext"
import {
  getDateYMD,
  textEdit,
  titleEdit,
  getThumb,
  getEpisode,
  getRawThumb,
  customCount,
  onShare,
} from "utils"
import smallShare from "images/smallShare.png"
import DisplayB from "components/tools/banner/displayB"
import peachIcons from "images/peachIcons.png"
import SlidePagination from "components/tools/slide/slidePagination.js"
import { getBannerAsync } from "store/modules/bannerReducer.js"
import dompurify from "dompurify"
import NewTag from "components/tools/newTag"
import dayjs from "dayjs"
const Detail = props => {
  const { news_no, currentPage, pagePostSize } = queryString.parse(
    props.location.search
  )
  const dispatch = useDispatch()
  const getNoticeList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getNoticeListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )
  const getBanner = useCallback(
    position => dispatch(getBannerAsync(position)),
    [dispatch]
  )
  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const { noticeList, noticeTotal } = useSelector(
    ({ postReducer: { notice } }) => notice,
    shallowEqual
  )

  const [slidePage, setSlidePage] = useState(Number(currentPage))

  const [post, setPost] = useState({})
  useEffect(() => {
    ;(async () => {
      const result = await api.getNoticeDetail(news_no)
      console.log(result)
      if (result)
        setPost({
          no: result.news_no,
          title: result.title,
          contents: result.bodyText,
          hit: result.hit,
          time: getDateYMD(result.registerTime, "YYYY.MM.DD"),
          keyword: result.label,
        })
      await getBanner("notice")
    })()
  }, [props, news_no, currentPage, pagePostSize])

  return (
    <Layout>
      <div className={styles.con}>
        <div className={styles.head}>
          <div>
            <div className={styles.tag}>
              <div
                style={{
                  backgroundColor: "#E95C63",
                  padding: "0 10px 0 10px",
                  height: "22px",
                  marginRight: "5px",
                  fontWeight: "400",
                }}
              >
                {post.keyword}
              </div>
              <NewTag registerTime={post?.time} />
            </div>
            <div className={styles.title}>{post.title}</div>
            <div className={styles.writer}>
              <div>
                <img src={peachIcons} alt="" />
                <div>피치서가</div>
                <div>{post.time}</div>
              </div>
              <div>
                <div>view</div>
                <div>{post.hit}</div>
                <img
                  src={smallShare}
                  alt=""
                  onClick={() => onShare(setmodalOpen, setModalMain)}
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {typeof window !== "undefined" ? (
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{
              __html: post.contents,
            }}
            key={dayjs()}
          />
        ) : null}
        <SlidePagination
          total={noticeTotal}
          currentPage={slidePage}
          setCurrentPage={setSlidePage}
          postSize={pagePostSize}
          getlist={getNoticeList}
          viewAll={() => {
            navigate("/visible/notice/list")
          }}
          title={"피치서가 소식"}
          line={true}
        >
          <div className={styles.pageSlideCon}>
            {noticeList.map((post, i) => (
              <div
                key={post.news_no}
                onClick={() => {
                  navigate(
                    `/notice/detail/?news_no=${post.news_no}&currentPage=${slidePage}&pagePostSize=${pagePostSize}`
                  )
                }}
                style={{
                  fontWeight: Number(news_no) === post.news_no ? "700" : "",
                  cursor: Number(news_no) === post.news_no ? "" : "pointer",
                }}
              >
                <div>
                  <div>
                    {customCount(
                      getEpisode(noticeTotal, slidePage, pagePostSize, i)
                    )}
                    화
                  </div>
                  <div>{post.title}</div>
                </div>
                <div>{post.hit} 읽음</div>
              </div>
            ))}
          </div>
        </SlidePagination>
        <DisplayB
          style={{
            width: "780px",
            height: "147px",
            margin: "96px auto 30px auto",
          }}
        />
      </div>
    </Layout>
  )
}

export default Detail
